import { EFontWeight, EFonts } from 'src/config/enums';
import styled, { css } from 'styled-components';

import { OxWaveText } from 'src/components/OxWaveText';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';

export const Container = styled.div<{ buttonPosition: string }>(
    ({ buttonPosition }) => css`
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        z-index: 10;
        flex-direction: column;
        ${buttonPosition === 'bottom' &&
        css`
            justify-content: flex-end;
            svg {
                ${fluidSizing([
                    { prop: 'width', values: [70, 70, 70] },
                    { prop: 'padding-bottom', values: [50, 50, 110] },
                    { prop: 'padding-top', values: [50, 50, 50] }
                ])};
            }
        `}
        ${buttonPosition === 'top' &&
        css`
            justify-content: flex-start;
            svg {
                ${fluidSizing([
                    { prop: 'width', values: [70, 70, 70] },
                    { prop: 'padding-bottom', values: [50, 50, 110] },
                    { prop: 'padding-top', values: [50, 50, 50] }
                ])};
            }
        `}
  ${(buttonPosition === 'center' || !buttonPosition) &&
        css`
            justify-content: center;
            svg {
                ${fluidSizing([{ prop: 'width', values: [70, 70, 70] }])};
            }
        `}
    `
);

export const Label = styled(OxWaveText)(
    ({ theme }) => css`
        color: ${theme.colors.basic.white};
        ${createTextStyle(EFonts.Hatton, EFontWeight.Medium, [14, 14, 14], [34, 34, 34])}
    `
);
