import * as Styled from './OxCoreVideoPlayer.styled';

import React, { createRef, useEffect, useState } from 'react';

import { OxVideoPlayerOverlayer } from '../OxVideoPlayerOverlayer';
import { useInView } from 'src/hooks/useInViewport';
import { OxIcon } from 'src/components/OxIcon';
import { useBreakpoints } from 'src/hooks';
import { getMuxVideoLink, getMuxPosterLink } from 'src/utils/muxHelpers';
import { OnyxError } from 'src/helpers/OnyxError';
import { EAlertVariant } from 'src/context/AlertContext';
import { Video } from 'src/services/cms/cms.types';
import Hls from '../../../../../node_modules/hls.js/dist/hls.light.min.js';

type TSrc = string | Video[];

export type TOxCoreVideoPlayerProps = {
    src: TSrc;
    playing?: boolean;
    autoPlay?: boolean;
    loop?: boolean;
    muted?: boolean;
    controls?: boolean;
    playsInline?: boolean;
    controlsWhenPlay?: boolean;
    usePlayButtonOverlayer?: boolean;
    addStopButton?: boolean;
    fitVideo?: boolean;
    isVisible?: boolean;
    overlayerLabel?: string;
    overlayerButtonPosition?: 'top' | 'center' | 'bottom';
    hideOberlayerButton?: boolean;
    poster?: string;
    playIfOutOfView?: boolean;
    onPlay?: () => void;
    onPause?: () => void;
    onEnd?: () => void;
};

export const OxCoreVideoPlayer = React.forwardRef<HTMLVideoElement, TOxCoreVideoPlayerProps>(
    (props: SCProps<'div', TOxCoreVideoPlayerProps>, ref): JSX.Element => {
        const device = useBreakpoints();
        let playerRef = ref;

        if (!playerRef) {
            playerRef = createRef<HTMLVideoElement>();
        }

        let src = props.src;
        let poster = props.poster;
        let ratio;
        let viewportVideo;

        if (Array.isArray(props.src) && (props.src.length ?? 0) > 0) {
            viewportVideo = props.src.find(
                (item) =>
                    item.device.length === 0 ||
                    item.device.findIndex((breakpoint: string) => !!device[breakpoint]) >= 0
            );
        } else {
            viewportVideo = props;
        }

        if (viewportVideo) {
            src = getMuxVideoLink(viewportVideo.mux?.asset?.playbackId) ?? '';

            poster = poster ?? getMuxPosterLink(viewportVideo.mux?.asset?.playbackId, 0);

            const ratioArr = viewportVideo.videoRatio?.split(':');

            if (ratioArr.length !== 2) {
                try {
                    throw new OnyxError({
                        type: EAlertVariant.Error,
                        title: 'Incorrectly formatted video ratio',
                        message: 'Should be in format x:y where x and y are integers'
                    });
                } catch (e) {
                    console.error(e);
                }
            } else {
                ratio = parseInt(ratioArr[1]) / parseInt(ratioArr[0]);
            }
        }

        const [containerRef, inView] = useInView();
        const [controls, setControls] = useState(false);
        const [videoPlaying, setVideoPlaying] = useState(
            props.playing || (props.autoPlay && props.muted) || false
        );

        const loadVideo = (): void => {
            const video = playerRef?.current;

            if (!src || !video) return;

            if (video.canPlayType('application/vnd.apple.mpegurl') || !src.includes('m3u8')) {
                video.src = src;
            } else if (Hls.isSupported()) {
                const hls = new Hls();
                hls.loadSource(src);
                hls.attachMedia(video);
            }
        };

        const playVideo = (): void => {
            const video = playerRef?.current;
            setVideoPlaying(true);
            video.play();
        };

        const pauseVideo = (): void => {
            const video = playerRef?.current as HTMLVideoElement;
            video.pause();
        };

        const togglePlay = (): void => {
            videoPlaying ? pauseVideo() : playVideo();
        };

        const onVideoPause = (): void => {
            props.onPause && props.onPause();
            setVideoPlaying(false);
        };

        const onVideoPlay = (): void => {
            props.onPlay && props.onPlay();
            setVideoPlaying(true);
        };

        const onVideoEnd = (): void => {
            props.onEnd && props.onEnd();
        };

        useEffect(() => {
            inView && loadVideo();

            playerRef.current?.addEventListener('ended', onVideoEnd);
        }, [playerRef.current, inView]);

        useEffect(() => {
            if (videoPlaying && !inView && !props.playIfOutOfView && !props.autoPlay) {
                setVideoPlaying(false);
            }
        }, [inView]);

        useEffect(() => {
            if (!controls && props.controlsWhenPlay) {
                setControls(!!videoPlaying);
            } else {
                setControls(!!controls);
            }
        }, [controls, props.controlsWhenPlay]);

        useEffect(() => {
            const video = playerRef?.current;
            if (!controls && props.controlsWhenPlay) {
                setControls(!!videoPlaying);
            }
            if (!videoPlaying) {
                video.pause();
            }
        }, [videoPlaying]);

        useEffect(() => {
            if (props.playing === false) {
                setVideoPlaying(false);
            }
        }, [props.playing]);

        useEffect(() => {
            return () => playerRef?.current?.removeEventListener('ended', onVideoEnd);
        }, []);

        return (
            <Styled.Container ref={containerRef} className={props.className} ratio={ratio}>
                <Styled.Player
                    ref={playerRef}
                    controls={controls}
                    autoPlay={props.autoPlay}
                    muted={props.muted}
                    loop={props.loop}
                    playsInline={props.playsInline}
                    fitVideo={props.fitVideo}
                    poster={poster}
                    preload={props.preload ? props.preload : 'none'}
                    onPause={onVideoPause}
                    onPlay={onVideoPlay}
                />
                {props.usePlayButtonOverlayer && !videoPlaying && (
                    <OxVideoPlayerOverlayer
                        onClick={playVideo}
                        buttonPosition={props.overlayerButtonPosition || 'center'}
                        hideButton={props.hideOberlayerButton}
                        label={props.overlayerLabel}
                        isPlay={
                            props.isVisible !== undefined
                                ? !videoPlaying && props.isVisible
                                : !videoPlaying
                        }
                    />
                )}
                {props.addStopButton && (
                    <Styled.PlayPauseButton onClick={togglePlay} playing={videoPlaying}>
                        <OxIcon
                            size={60}
                            title={`${videoPlaying ? 'Pause' : 'Play'} video`}
                            name={`video-${videoPlaying ? 'pause' : 'play'}-button`}
                        />
                    </Styled.PlayPauseButton>
                )}
            </Styled.Container>
        );
    }
);
